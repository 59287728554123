import React, { useState } from "react";

const TestClassificationComponent = () => {
  const [resultClass, setResultClass] = useState("");
  const [resultConfidence, setResultConfidence] = useState("");
  const [showResult, setShowResult] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
    const fileInput = document.getElementById("fileInput");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);

    // Replace with your FastAPI endpoint
    const url = "https://fastapi-classification-service-168025477090.us-central1.run.app/upload-image";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setResultClass(data.class);
        setResultConfidence(data.confidence.toFixed(2));
        setShowResult(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div style={styles.body}>
      <h1 style={styles.title}>Insect Image Classification</h1>
      <div style={styles.uploadForm}>
        <form id="uploadForm" onSubmit={submitForm}>
          <input type="file" id="fileInput" />
          <button type="submit" style={styles.button}>Predict</button>
        </form>
      </div>
      {showResult && (
        <div style={styles.resultContainer}>
          <h2>Classification Result</h2>
          <p><strong>Class: </strong>{resultClass}</p>
          <p><strong>Confidence: </strong>{resultConfidence}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  body: {
    fontFamily: "Arial, sans-serif",
    margin: 0,
    padding: "20px",
    backgroundColor: "#00000070",
    backdropFilter: "blur(4px)",
    minHeight: "100vh",
    backgroundBlendMode: "overlay",
    backgroundImage: 'url("https://i.ibb.co/B3yDkpV/pexels-larissa-megale-1076233.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center top",
  },
  title: {
    textAlign: "center",
    color: "#fff",
  },
  uploadForm: {
    maxWidth: "400px",
    margin: "0 auto",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  button: {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    marginTop: "10px",
    cursor: "pointer",
    borderRadius: "4px",
  },
  resultContainer: {
    maxWidth: "400px",
    margin: "20px auto",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
};

export default TestClassificationComponent;
