import React, { useState, useEffect } from "react";
import styles from "./classifications.module.css";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../../config/firebase-config"; // Adjust the path as needed


import SearchBarHeader from "../UniversalComponents/search_bar_header";
import ClassificationsCard from "./ClassificationCard";

function ClassificationsComponent() {
  const [classifications, setClassifications] = useState([]);
  const [sortOption, setSortOption] = useState("newest"); // Default sort option

  // Fetch Firestore data whenever sortOption changes
  useEffect(() => {
    const classificationsRef = collection(db, "classifications");
    let q;

    if (sortOption === "alphabetical") {
      q = query(classificationsRef, orderBy("class")); // Sort by class name
    } else {
      q = query(classificationsRef, orderBy("timestamp", "desc")); // Sort by newest (timestamp)
    }

    // Fetch data from Firestore with real-time listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const classificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClassifications(classificationsData);
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [sortOption]);

  // Handle sort option change
  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Classifications</p>
      <SearchBarHeader />

      {/* Sort Dropdown */}
      <div>
        <label
          htmlFor="sort"
          style={{ marginRight: "10px", fontWeight: "500", fontSize: "16px" }}
        >
          Sort by:
        </label>
        <select
          id="sort"
          value={sortOption}
          onChange={handleSortChange}
          className={styles.sort_dropdown}
        >
          <option value="newest">Newest</option>
          <option value="alphabetical">Alphabetical</option>
        </select>
      </div>

      <div className={styles.table_scroll}>
        {classifications.map((classification) => (
          <ClassificationsCard
            key={classification.id}
            classification={classification}
          />
        ))}
        <div className={styles.buffer}></div>
      </div>
    </div>
  );
}

export default ClassificationsComponent;
