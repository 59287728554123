import React from "react";
import styles from "./classifications.module.css";

function ClassificationsCard(props) {
  const classification = props.classification;

  // Log image URL and class for debugging purposes
  console.log("Image URL:", classification.image_url);
  console.log("Class name (label):", classification.class);

  return (
    <div className={styles.classifications_card}>
      {/* Display the image URL from Firestore */}
      <img
        className={styles.classifications_image}
        src={classification.image_url} // Ensure this is a valid public URL
        alt={classification.class} // Alt text reflecting the class name
        onError={(e) => { e.target.onerror = null; e.target.src = "fallback-image-url.png"; }} // Optional fallback image
      />
      <div className={styles.classifications_info}>
        <div className={styles.classifications_subinfo}>
          {/* Display class name, renamed to avoid conflicts */}
          <p className={styles.classification_title}>{classification.class || classification.classificationLabel}</p>
          
          {/* Display confidence score with three significant figures */}
          <p className={styles.classification_info_title}>
            Confidence Score: {(classification.confidence * 100).toPrecision(3)}%
          </p>
          
          {/* Display timestamp */}
          <p className={styles.classification_info_title}>
            Date: {new Date(classification.timestamp.seconds * 1000).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClassificationsCard;
