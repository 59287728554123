// SignUpComponent.js
import React from "react";
import { useState, useContext } from "react";
import styles from "./auth.module.css";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";

const SignUpComponent = () => {
  const { createAccount } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);

  async function handleSubmit() {
    try {
      await createAccount(email, password, firstName, lastName);
      navigate("/dashboard");
    } catch (error) {
      setError("Failed to create an account. Please try again.");
      console.error("Sign-up error:", error);
    }
  }

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img alt="logo" src="/Favicon.png" className={styles.logo_icon}></img>
          <p className={styles.page_title}>Sign Up</p>
          <p className={styles.login_input_titles}>First Name</p>
          <input
            onChange={(event) => setFirstName(event.target.value)}
            className={styles.input}
            placeholder="First Name"
          ></input>
          <p className={styles.login_input_titles}>Last Name</p>
          <input
            onChange={(event) => setLastName(event.target.value)}
            className={styles.input}
            placeholder="Last Name"
          ></input>
          <p className={styles.login_input_titles}>Email Address</p>
          <input
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
            placeholder="Email"
          ></input>
          <p className={styles.login_input_titles}>Password</p>
          <input
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            className={styles.input}
            placeholder="Password"
          ></input>
          {error && <p className={styles.error_text}>{error}</p>}
          <div onClick={handleSubmit} className={styles.page_button}>
            <p className={styles.join_button_text}>Sign Up</p>
          </div>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/NyteLyfeHomeAbstractBackground.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default SignUpComponent; // Ensure default export
